import React, { useState, useEffect } from 'react';
import './IcpForm.css';
import PersonInfoFilter from './searchFilters/searchFilters';
import { useLocation } from 'react-router-dom';

const IcpForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [partnerCode, setPartnerCode] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [verificationText, setVerificationText] = useState('Verification code sent to email.');
  const [showFilters, setShowFilters] = useState(false);
  const location = useLocation();



  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const codeFromUrl = searchParams.get('partner_code');
    if (codeFromUrl) {
      setPartnerCode(codeFromUrl);
    }
  }, [location]);

  const [personFilterTags, setPersonFilterTags] = useState({
    firstName: [],
    lastName: [],
    email: [],
    title: [],
    seniority: [],
    function: [],
  });
  const [companyFilterTags, setCompanyFilterTags] = useState({
    companyName: [],
    industry: [],
    teamSize: [],
    companyDomain: [],
    revenue: [],
  });
  const [companyLocationFilterTags, setCompanyLocationFilterTags] = useState({
    companyCity: [],
    companyState: [],
    companyCountry: [],
    companyZip: [],
  });
  const [leadLocationFilterTags, setLeadLocationFilterTags] = useState({
    leadCity: [],
    leadState: [],
    leadCountry: [],
    otherInformation: [],
  });
  const handleNextClick = () => {
    if (name.trim() !== '' && email.trim() !== '' && validateEmail(email)) {
      setShowFilters(true);
    } else {
      alert('Please enter a valid name and email address.');
    }
  };

  const handleBackClick = () => {
    setShowFilters(false);
  };

  const clearAllFilters = () => {
    setPersonFilterTags({
      firstName: [],
      lastName: [],
      email: [],
      title: [],
      seniority: [],
      function: [],
    });
    setCompanyFilterTags({
      companyName: [],
      industry: [],
      teamSize: [],
      companyDomain: [],
      revenue: [],
    });
    setCompanyLocationFilterTags({
      companyCity: [],
      companyState: [],
      companyCountry: [],
      companyZip: [],
    });
    setLeadLocationFilterTags({
      leadCity: [],
      leadState: [],
      leadCountry: [],
      otherInformation: [],
    });
  };


  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleCodeChange = (index, value) => {
    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);
  };

  const handleSubmitClick = () => {
    // Perform verification code submission logic here
    console.log('Verification code submitted:', verificationCode.join(''));
  };

  const handleResendClick = () => {
    // Resend verification code logic here
    setVerificationText('Verification code resent to email.');
  };

  return (
    <div className="form-container">
      <h3>Ideal Customer Profile Form</h3>
      {!showFilters ? (
        <>

          <div className="form-content">
            <div className='form-heading-container'>
              <div className='form-heading-number'><p>1</p></div>
              <p className="form-heading">Enter Your Details.</p>
              <p className="form-heading-page-count">1 / 2</p>
            </div>
            <hr />

            <form className="form-details">
              <label className='form-lable-input' htmlFor="name">Name:*</label>
              <input
                placeholder='Enter your name'
                type="text"
                className='form-input'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <label className='form-lable-input' htmlFor="email">Email:*</label>
              <input
                placeholder='Enter your email'
                type="email"
                className='form-input'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <label className='form-lable-input' htmlFor="email">Partner Code:*</label>
              <input
                placeholder='Enter your partner code'
                type="text"
                className='form-input'
                required
                value={partnerCode}
                onChange={(e) => setPartnerCode(e.target.value)}
              />

              <button className='next-button' type="button" onClick={handleNextClick}>
                Next
              </button>
            </form>

            {/* <div>
            <p>{verificationText}</p>
            <div className="code-input">
              {verificationCode.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  required
                />
              ))}
            </div>
            <div className="form-buttons">
              <p type="button" onClick={handleResendClick}>
                Resend Code?
              </p>
              <button type="button" onClick={handleSubmitClick}>
                Submit
              </button>
            </div>
          </div> */}

          </div>
        </>
      ) : (
        <PersonInfoFilter
          name={name}
          email={email}
          partnerCode={partnerCode}
          onBack={handleBackClick}
          personFilterTags={personFilterTags}
          setPersonFilterTags={setPersonFilterTags}
          companyFilterTags={companyFilterTags}
          setCompanyFilterTags={setCompanyFilterTags}
          companyLocationFilterTags={companyLocationFilterTags}
          setCompanyLocationFilterTags={setCompanyLocationFilterTags}
          leadLocationFilterTags={leadLocationFilterTags}
          setLeadLocationFilterTags={setLeadLocationFilterTags}
          clearAllFilters={clearAllFilters}
        />
      )}
    </div>
  );
};

export default IcpForm;
