import React, { useState, useContext, useEffect, useRef } from 'react';
import './searchFilters.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DropdownData from './dropdownData';
import { Country, State, City } from 'country-state-city';
import Notification from '../notification/notification';



const { TITLES, TEAM_SIZES, REVENUE, CountriesData, INDUSTRIES, Seniority, FUNCTION } = DropdownData;



const PersonInfoFilter = ({
    name,
    email,
    partnerCode,
    onBack,
    personFilterTags,
    setPersonFilterTags,
    companyFilterTags,
    setCompanyFilterTags,
    companyLocationFilterTags,
    setCompanyLocationFilterTags,
    leadLocationFilterTags,
    setLeadLocationFilterTags,
    clearAllFilters,
  }) => {
    const titleInputRef = useRef(null);
    const seniorityInputRef = useRef(null);
    const functionInputRef = useRef(null);
    const teamSizeInputRef = useRef(null);
    const revenueInputRef = useRef(null);
    const industryInputRef = useRef(null);
    const companyCityInputRef = useRef(null);
    const companyStateInputRef = useRef(null);
    const companyCountryInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const [selectedCompanyCountry, setSelectedCompanyCountry] = useState('');
    const [selectedCompanyState, setSelectedCompanyState] = useState('');
    const [selectedCompanyCity, setSelectedCompanyCity] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [notification, setNotification] = useState({
        type: '', // 'success', 'error', 'info'
        message: ''
    });









    const [personFilters, setPersonFilters] = useState({
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        seniority: '',
        function: ''
    });
    const [companyFilters, setCompanyFilters] = useState({
        companyName: '',
        industry: '',
        teamSize: '',
        companyDomain: '',
        revenue: ''
    });
    const [phoneFilters, setPhoneFilters] = useState({
        work: '',
        personal: '',
        company: ''
    });

    const [companyLocationFilters, setCompanyLocationFilters] = useState({
        companyCity: '',
        state: '',
        companyCountry: '',
        companyZip: ''
    })

    const [leadLocationFilters, setLeadLocationFilters] = useState({
        leadCity: '',
        leadState: '',
        leadCountry: '',
        otherInformation: ''
    });


    const [activePersonDropdown, setActivePersonDropdown] = useState(null);
    const [activeCompanyDropdown, setActiveCompanyDropdown] = useState(null);
  

    const handleTogglePersonDropdown = (field) => {
        setActivePersonDropdown(activePersonDropdown === field ? null : field);
    };
    const handleToggleCompanyDropdown = (field) => {
        setActiveCompanyDropdown(activeCompanyDropdown === field ? null : field);
    };



    // For company filters
    const handleCompanyFilterChange = (e, field) => {
        setCompanyFilters(prevState => ({
            ...prevState,
            [field]: e.target.value  // Removed .trim()
        }));
    };

    // For person filters
    const handlePersonFilterChange = (e, field) => {
        setPersonFilters(prevState => ({
            ...prevState,
            [field]: e.target.value  // Removed .trim()
        }));
    };

    // For company location filters
    const handleCompanyLocationChange = (e, field) => {
        const { value } = e.target;
        setCompanyLocationFilters(prevState => ({
            ...prevState,
            [field]: value  // Removed .trim()
        }));
    };

    // For lead location filters
    const handleLeadLocationChange = (e, field) => {
        const { value } = e.target;
        setLeadLocationFilters(prevState => ({
            ...prevState,
            [field]: value  // Removed .trim()
        }));
    };

    const handleLeadLocationKeyPress = (e, field) => {
        if (e.key === 'Enter') {
            e.preventDefault();  // Prevent default form submission
            applyLeadLocationFilter(field);
        }
    };


    const handlePersonKeyPress = (e, field) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            applyPersonFilter(field);
        }
    };

    const handleCompanyKeyPress = (e, field) => {
        if (e.key === 'Enter') {
            applyCompanyFilter(field);
        }
    };

    const handleCompanyLocationKeyPress = (event, field) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            applyCompanyLocationFilter(field);
        }
    };

    const applyLeadLocationFilter = (field) => {
        const value = leadLocationFilters[field];
        if (value !== '') {
            if (leadLocationFilterTags[field].includes(value)) {
                alert('Filter Already Added');
                setLeadLocationFilters(prevFilters => ({
                    ...prevFilters,
                    [field]: ''
                }));
            } else {
                setLeadLocationFilterTags(prevTags => ({
                    ...prevTags,
                    [field]: [...prevTags[field], value]
                }));
                setLeadLocationFilters(prevFilters => ({
                    ...prevFilters,
                    [field]: ''
                }));
            }
        }
    };

    const applyCompanyLocationFilter = (field) => {
        const value = companyLocationFilters[field].trim();

        if (value !== '') {
            const tagField = field === 'companyState' ? 'companyState/Region' : field;
            console.log(`${tagField} filter: ${value}`);

            if (companyLocationFilterTags[tagField] && companyLocationFilterTags[tagField].includes(value)) {
                alert('Filter Already Added');
                setCompanyLocationFilters(prevState => ({
                    ...prevState,
                    [field]: ''
                }));
            } else {
                setCompanyLocationFilterTags(prevState => ({
                    ...prevState,
                    [tagField]: [...(prevState[tagField] || []), value]
                }));

                setCompanyLocationFilters(prevState => ({
                    ...prevState,
                    [field]: ''
                }));
            }
        }
    };



    const applyPersonFilter = (field) => {
        const value = personFilters[field];
        if (value !== '') {
            if (personFilterTags[field].includes(value)) {
                alert('Filter already applied');
                setPersonFilters(prevState => ({
                    ...prevState,
                    [field]: ''
                }));
            } else {
                // Apply your filter logic here
                console.log(`${field} filter: ${value}`);
                // Add filter to filter tags
                setPersonFilterTags(prevState => ({
                    ...prevState,
                    [field]: [...prevState[field], value]
                }));
                // Clear the input field after applying the filter
                setPersonFilters(prevState => ({
                    ...prevState,
                    [field]: ''
                }));
            }
        }
    };

    const applyCompanyFilter = (field) => {
        const value = companyFilters[field];
        if (value !== '') {
            if (companyFilterTags[field].includes(value)) {
                alert('Filter Already Added');
                setCompanyFilters(prevState => ({
                    ...prevState,
                    [field]: ''
                }));
            } else {
                // Apply your filter logic here
                console.log(`${field} filter: ${value}`);
                // Add filter to filter tags
                setCompanyFilterTags(prevState => ({
                    ...prevState,
                    [field]: [...prevState[field], value]
                }));
                // Clear the input field after applying the filter
                setCompanyFilters(prevState => ({
                    ...prevState,
                    [field]: ''
                }));
            }
        }
    };

    const removePersonTag = (field, index) => {
        setPersonFilterTags(prevState => ({
            ...prevState,
            [field]: prevState[field].filter((_, i) => i !== index)
        }));
    };

    const removeCompanyTag = (field, index) => {
        setCompanyFilterTags(prevState => ({
            ...prevState,
            [field]: prevState[field].filter((_, i) => i !== index)
        }));
    };
    const removeCompanyLocationTag = (field, index) => {
        const tagField = field === 'companyState/Region' ? 'companyState/Region' : field;
        setCompanyLocationFilterTags(prevTags => ({
            ...prevTags,
            [tagField]: prevTags[tagField].filter((_, i) => i !== index)
        }));
    };

    const removeLeadLocationTag = (field, index) => {
        setLeadLocationFilterTags(prevTags => ({
            ...prevTags,
            [field]: prevTags[field].filter((_, i) => i !== index)
        }));
    };





    const totalActiveFilters = () => {
        return Object.values({ ...personFilterTags, ...companyFilterTags, ...companyLocationFilterTags, ...leadLocationFilterTags }).reduce((acc, tags) => acc + tags.length, 0);
    };



    const handleSubmit = async () => {
        setIsLoading(true);
        console.log('submit')
        const filters = {
            ...Object.fromEntries(Object.entries(personFilterTags).filter(([_, tags]) => tags.length > 0)),
            ...Object.fromEntries(Object.entries(companyFilterTags).filter(([_, tags]) => tags.length > 0)),
            ...Object.fromEntries(Object.entries(companyLocationFilterTags).filter(([_, tags]) => tags.length > 0)),
            ...Object.fromEntries(Object.entries(leadLocationFilterTags).filter(([_, tags]) => tags.length > 0))
        };

        try {

            await axios.post('https://crawl.onepgr.com:3004/send-email', {
                name,
                email,
                partnerCode,
                filters,
            });
            alert('Your Request sent successfully!');
            clearAllFilters();
            console.log('Email sent successfully!')
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send email. Please try again.');
        } finally {
            setIsLoading(false); // Set isLoading back to false after the request is completed
        }
    };



    return (
        <div className='search-filters-container'>
            <div className='filter-search'>
                <div className='filter-heading'>
                    <div className='form-heading-container'>
                        <div className='form-heading-number'><p>2</p></div>
                        <p className="form-heading">Create your data request using the filters below.</p>
                        {totalActiveFilters() > 0 && (
                            <div className="filter-count">
                                <span>{totalActiveFilters()} </span>
                                <button onClick={clearAllFilters}>x</button>
                            </div>
                        )}
                        <p className="form-heading-page-count">2 / 2</p>
                    </div>

                </div>
                <hr />

                <div className='filter-cards'>
                    <div className="person-info-filter">
                        <div className="location-heading">
                            <p>Location</p>

                        </div>
                        <div className='dropdown-container'>
                            <div className="dropdown">
                                <label>Country:</label>
                                <input
                                    type="text"
                                    ref={companyCountryInputRef}
                                    value={companyLocationFilters.companyCountry}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleCompanyLocationChange(e, 'companyCountry');
                                        setSelectedCompanyCountry(selectedValue);
                                        setSelectedCompanyState('');
                                        setSelectedCompanyCity('');
                                        setCompanyLocationFilters((prevFilters) => ({
                                            ...prevFilters,
                                            companyState: '',
                                            companyCity: '',
                                        }));
                                        if (
                                            selectedValue !== '' &&
                                            Country.getAllCountries().some((country) => country.name === selectedValue)
                                        ) {
                                            if (!companyLocationFilterTags.companyCountry.includes(selectedValue)) {
                                                setCompanyLocationFilterTags(prevState => ({
                                                    ...prevState,
                                                    companyCountry: [...prevState.companyCountry, selectedValue]
                                                }));
                                            }
                                            setCompanyLocationFilters(prevState => ({
                                                ...prevState,
                                                companyCountry: ''
                                            }));
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            applyCompanyLocationFilter('companyCountry');
                                        }
                                    }}
                                    placeholder="Country"
                                    list="company-country-options"
                                />
                                <datalist id="company-country-options">
                                    {Country.getAllCountries().map((country) => (
                                        <option key={country.isoCode} value={country.name} />
                                    ))}
                                </datalist>
                                <div className="filter-tags">
                                    {companyLocationFilterTags.companyCountry.map((tag, index) => (
                                        <div key={index} className="filter-tag">
                                            {tag}
                                            <button onClick={() => removeCompanyLocationTag('companyCountry', index)}>x</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="dropdown">
                                <label>State:</label>
                                <input
                                    type="text"
                                    ref={companyStateInputRef}
                                    value={companyLocationFilters.companyState}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleCompanyLocationChange(e, 'companyState');
                                        setSelectedCompanyState(selectedValue);
                                        setSelectedCompanyCity('');
                                        setCompanyLocationFilters((prevFilters) => ({
                                            ...prevFilters,
                                            companyCity: '',
                                        }));
                            
                                        if (
                                            selectedValue !== '' &&
                                            selectedCompanyCountry &&
                                            State.getStatesOfCountry(
                                                Country.getAllCountries().find(
                                                    (country) => country.name === selectedCompanyCountry
                                                )?.isoCode
                                            ).some((state) => state.name === selectedValue)
                                        ) {
                                            setCompanyLocationFilterTags((prevState) => {
                                                const companyStateRegion = prevState['companyState/Region'] || [];
                                                if (!companyStateRegion.includes(selectedValue)) {
                                                    return {
                                                        ...prevState,
                                                        'companyState/Region': [...companyStateRegion, selectedValue],
                                                    };
                                                }
                                                return prevState;
                                            });
                            
                                            setCompanyLocationFilters((prevState) => ({
                                                ...prevState,
                                                companyState: '',
                                            }));
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            applyCompanyLocationFilter('companyState');
                                        }
                                    }}
                                    placeholder="State"
                                    list="company-state-options"
                                    disabled={!selectedCompanyCountry}
                                />
                                <datalist id="company-state-options">
                                    {selectedCompanyCountry &&
                                        State.getStatesOfCountry(
                                            Country.getAllCountries().find(
                                                (country) => country.name === selectedCompanyCountry
                                            )?.isoCode
                                        ).map((state) => (
                                            <option key={state.isoCode} value={state.name} />
                                        ))}
                                </datalist>
                                <div className="filter-tags">
                                    {companyLocationFilterTags['companyState/Region'] && companyLocationFilterTags['companyState/Region'].map((tag, index) => (
                                        <div key={index} className="filter-tag">
                                            {tag}
                                            <button onClick={() => removeCompanyLocationTag("companyState/Region", index)}>x</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="dropdown">
                                <label>City:</label>
                                <input
                                    type="text"
                                    ref={companyCityInputRef}
                                    value={companyLocationFilters.companyCity}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleCompanyLocationChange(e, 'companyCity');
                                        setSelectedCompanyCity(selectedValue);
                                        if (
                                            selectedValue !== '' &&
                                            selectedCompanyCountry &&
                                            selectedCompanyState &&
                                            City.getCitiesOfState(
                                                Country.getAllCountries().find(
                                                    (country) => country.name === selectedCompanyCountry
                                                )?.isoCode,
                                                State.getStatesOfCountry(
                                                    Country.getAllCountries().find(
                                                        (country) => country.name === selectedCompanyCountry
                                                    )?.isoCode
                                                ).find((state) => state.name === selectedCompanyState)?.isoCode
                                            ).some((city) => city.name === selectedValue)
                                        ) {
                                            if (!companyLocationFilterTags.companyCity.includes(selectedValue)) {
                                                setCompanyLocationFilterTags(prevState => ({
                                                    ...prevState,
                                                    companyCity: [...prevState.companyCity, selectedValue]
                                                }));
                                            }
                                            setCompanyLocationFilters(prevState => ({
                                                ...prevState,
                                                companyCity: ''
                                            }));
                                        }
                                    }}
                            
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            applyCompanyLocationFilter('companyCity');
                                        }
                                    }}
                                    placeholder="City"
                                    list="company-city-options"

                                />
                                <datalist id="company-city-options">
                                    {selectedCompanyCountry && selectedCompanyState &&
                                        City.getCitiesOfState(
                                            Country.getAllCountries().find(
                                                (country) => country.name === selectedCompanyCountry
                                            )?.isoCode,
                                            State.getStatesOfCountry(
                                                Country.getAllCountries().find(
                                                    (country) => country.name === selectedCompanyCountry
                                                )?.isoCode
                                            ).find((state) => state.name === selectedCompanyState)?.isoCode
                                        ).map((city) => (
                                            <option key={city.name} value={city.name} />
                                        ))}
                                </datalist>
                                <div className="filter-tags">
                                    {companyLocationFilterTags.companyCity.map((tag, index) => (
                                        <div key={index} className="filter-tag">
                                            {tag}
                                            <button onClick={() => removeCompanyLocationTag('companyCity', index)}>x</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="dropdown">
                                <label>Zip:</label>
                                <input
                                    type="text"
                                    value={companyLocationFilters.companyZip}
                                    onChange={(e) => handleCompanyLocationChange(e, 'companyZip')}
                                    onKeyPress={(e) => handleCompanyLocationKeyPress(e, 'companyZip', applyCompanyLocationFilter)}
                                    placeholder="Zip"
                                />
                                <div className="filter-tags">
                                    {companyLocationFilterTags.companyZip.map((tag, index) => (
                                        <div key={index} className="filter-tag">
                                            {tag}
                                            <button onClick={() => removeCompanyLocationTag('companyZip', index)}>x</button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='person-info-filter'>
                        <div className="location-heading">
                            <p>Company Information</p>

                        </div>
                        <div className='dropdown-container'>
                            {/* <div className="dropdown">
                                <label>Company Name:</label>
                                <input
                                    type="text"
                                    value={companyFilters.companyName}
                                    onChange={(e) => handleCompanyFilterChange(e, 'companyName')}
                                    onKeyPress={(e) => handleCompanyKeyPress(e, 'companyName')}
                                    placeholder="Search company name"
                                />
                                <div className="filter-tags">
                                    {companyFilterTags.companyName.map((tag, index) => (
                                        <div key={index} className="filter-tag">
                                            {tag}
                                            <button onClick={() => removeCompanyTag('companyName', index)}>x</button>
                                        </div>
                                    ))}
                                </div>
                            </div> */}
                            <div className="dropdown">
                                <label>Industry / Vertical:</label>
                                <input
                                    type="text"
                                    ref={industryInputRef}
                                    value={companyFilters.industry}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleCompanyFilterChange(e, 'industry');
                                        if (selectedValue !== '' && INDUSTRIES.includes(selectedValue)) {
                                            if (!companyFilterTags.industry.includes(selectedValue)) {
                                                setCompanyFilterTags(prevState => ({
                                                    ...prevState,
                                                    industry: [...prevState.industry, selectedValue]
                                                }));
                                            }
                                            setCompanyFilters(prevState => ({
                                                ...prevState,
                                                industry: ''
                                            }));
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            applyCompanyFilter('industry');
                                        }
                                    }}
                                    placeholder="Select Verticle"
                                    list="industry-options"
                                />
                                <datalist id="industry-options">
                                    {INDUSTRIES.map(industry => (
                                        <option key={industry} value={industry} />
                                    ))}
                                </datalist>
                                <div className="filter-tags">
                                    {companyFilterTags.industry.map((tag, index) => (
                                        <div key={index} className="filter-tag">
                                            {tag}
                                            <button onClick={() => removeCompanyTag('industry', index)}>x</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="dropdown">
                                <label>Revenue:</label>
                                <input
                                    type="text"
                                    ref={revenueInputRef}
                                    value={companyFilters.revenue}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleCompanyFilterChange(e, 'revenue');
                                        if (selectedValue !== '' && REVENUE.includes(selectedValue)) {
                                            if (!companyFilterTags.revenue.includes(selectedValue)) {
                                                setCompanyFilterTags(prevState => ({
                                                    ...prevState,
                                                    revenue: [...prevState.revenue, selectedValue]
                                                }));
                                            }
                                            setCompanyFilters(prevState => ({
                                                ...prevState,
                                                revenue: ''
                                            }));
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            applyCompanyFilter('revenue');
                                        }
                                    }}
                                    placeholder="Select Revenue Range"
                                    list="revenue-options"
                                />
                                <datalist id="revenue-options">
                                    {REVENUE.map(title => (
                                        <option key={title} value={title} />
                                    ))}
                                </datalist>
                                <div className="filter-tags">
                                    {companyFilterTags.revenue.map((tag, index) => (
                                        <div key={index} className="filter-tag">
                                            {tag}
                                            <button onClick={() => removeCompanyTag('revenue', index)}>x</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="dropdown">
                                <label>Headcount:</label>
                                <input
                                    type="text"
                                    ref={teamSizeInputRef}
                                    value={companyFilters.teamSize}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleCompanyFilterChange(e, 'teamSize');
                                        if (selectedValue !== '' && TEAM_SIZES.includes(selectedValue)) {
                                            if (!companyFilterTags.teamSize.includes(selectedValue)) {
                                                setCompanyFilterTags(prevState => ({
                                                    ...prevState,
                                                    teamSize: [...prevState.teamSize, selectedValue]
                                                }));
                                            }
                                            setCompanyFilters(prevState => ({
                                                ...prevState,
                                                teamSize: ''
                                            }));
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            applyCompanyFilter('teamSize');
                                        }
                                    }}
                                    placeholder="Select Headcount Range"
                                    list="teamSize-options"
                                />
                                <datalist id="teamSize-options">
                                    {TEAM_SIZES.map(title => (
                                        <option key={title} value={title} />
                                    ))}
                                </datalist>
                                <div className="filter-tags">
                                    {companyFilterTags.teamSize.map((tag, index) => (
                                        <div key={index} className="filter-tag">
                                            {tag}
                                            <button onClick={() => removeCompanyTag('teamSize', index)}>x</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* <div className="dropdown">
                                <label>Company Domain:</label>
                                <input
                                    type="text"
                                    value={companyFilters.companyDomain}
                                    onChange={(e) => handleCompanyFilterChange(e, 'companyDomain')}
                                    onKeyPress={(e) => handleCompanyKeyPress(e, 'companyDomain')}
                                    placeholder="Search company domain"
                                />
                                <div className="filter-tags">
                                    {companyFilterTags.companyDomain.map((tag, index) => (
                                        <div key={index} className="filter-tag">
                                            {tag}
                                            <button onClick={() => removeCompanyTag('companyDomain', index)}>x</button>
                                        </div>
                                    ))}
                                </div>
                            </div> */}

                        </div>
                    </div>


                    <div className="person-info-filter">

                        <div className="location-heading">
                            <p>Title Information</p>
                            {/* <div className="dotted-line"></div> */}
                        </div>

                        <div className="dropdown">
                            <label>Job Title:</label>
                            <input
                                type="text"
                                ref={titleInputRef}
                                value={personFilters.title}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    handlePersonFilterChange(e, 'title');
                                    if (selectedValue !== '' && TITLES.includes(selectedValue)) {
                                        if (!personFilterTags.title.includes(selectedValue)) {
                                            setPersonFilterTags(prevState => ({
                                                ...prevState,
                                                title: [...prevState.title, selectedValue]
                                            }));
                                        }
                                        setPersonFilters(prevState => ({
                                            ...prevState,
                                            title: ''
                                        }));
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handlePersonKeyPress(e, 'title');
                                    }
                                }}
                                placeholder="Job Title"
                                list="title-options"
                            />
                            <datalist id="title-options">
                                {TITLES.map(title => (
                                    <option key={title} value={title} />
                                ))}
                            </datalist>
                            <div className="filter-tags">
                                {personFilterTags.title.map((tag, index) => (
                                    <div key={index} className="filter-tag">
                                        {tag}
                                        <button onClick={() => removePersonTag('title', index)}>x</button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="dropdown">
                            <label>Seniority:</label>
                            <input
                                type="text"
                                ref={seniorityInputRef}
                                value={personFilters.seniority}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    handlePersonFilterChange(e, 'seniority');
                                    if (selectedValue !== '' && Seniority.includes(selectedValue)) {
                                        if (!personFilterTags.seniority.includes(selectedValue)) {
                                            setPersonFilterTags(prevState => ({
                                                ...prevState,
                                                seniority: [...prevState.seniority, selectedValue]
                                            }));
                                        }
                                        setPersonFilters(prevState => ({
                                            ...prevState,
                                            seniority: ''
                                        }));
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        applyPersonFilter('seniority');
                                    }
                                }}
                                placeholder="Select seniority"
                                list="seniority-options"
                            />
                            <datalist id="seniority-options">
                                {Seniority.map(level => (
                                    <option key={level} value={level} />
                                ))}
                            </datalist>
                            <div className="filter-tags">
                                {personFilterTags.seniority.map((tag, index) => (
                                    <div key={index} className="filter-tag">
                                        {tag}
                                        <button onClick={() => removePersonTag('seniority', index)}>x</button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="dropdown">
                            <label>Function:</label>
                            <input
                                type="text"
                                ref={functionInputRef}
                                value={personFilters.function}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    handlePersonFilterChange(e, 'function');
                                    if (selectedValue !== '' && FUNCTION.includes(selectedValue)) {
                                        if (!personFilterTags.function.includes(selectedValue)) {
                                            setPersonFilterTags(prevState => ({
                                                ...prevState,
                                                function: [...prevState.function, selectedValue]
                                            }));
                                        }
                                        setPersonFilters(prevState => ({
                                            ...prevState,
                                            function: ''
                                        }));
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        applyPersonFilter('function');
                                    }
                                }}
                                placeholder="Function"
                                list="function-options"
                            />
                            <datalist id="function-options">
                                {FUNCTION.map(level => (
                                    <option key={level} value={level} />
                                ))}
                            </datalist>
                            <div className="filter-tags">
                                {personFilterTags.function.map((tag, index) => (
                                    <div key={index} className="filter-tag">
                                        {tag}
                                        <button onClick={() => removePersonTag('function', index)}>x</button>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>


                    <div className="person-info-filter">
                        <div className="location-heading">
                            <p>Other Information</p>
                        </div>
                        <div className='dropdown-container'>
                            <div className="dropdown">
                                <label>Please provide any additional instructions for your order/specific items you want to be considered for research:</label>
                                <input
                                    type="text"
                                    value={leadLocationFilters.otherInformation}
                                    onChange={(e) => handleLeadLocationChange(e, 'otherInformation')}
                                    onKeyPress={(e) => handleLeadLocationKeyPress(e, 'otherInformation')}
                                    placeholder="Enter additional instructions"
                                />
                                <div className="filter-tags">
                                    {leadLocationFilterTags.otherInformation.map((tag, index) => (
                                        <div key={index} className="filter-tag">
                                            {tag}
                                            <button onClick={() => removeLeadLocationTag('otherInformation', index)}>x</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>

                    </div>






                </div>
                <div className="filter-actions-btn">
                    <button type="button" onClick={onBack} >Back</button>
                    <button onClick={clearAllFilters} className='filters-reset-btn'>Reset</button>
                    <button type="button" onClick={handleSubmit} >Submit</button>
                </div>
                {notification.message && (
                    <Notification type={notification.type} message={notification.message} />
                )}
                {isLoading && (
                    <div className="overlay-loading">
                        <div className="spinner"></div>
                    </div>
                )}


            </div>
        </div>
    );
};

export default PersonInfoFilter;
