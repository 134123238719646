
import React, { useContext, useState, useRef, useEffect } from 'react';
import './navBar.css';
import onepgrLogo from '../../images/onepgr-logo.webp';



const NavBar = () => {

    return (
        <nav className="navbar">
            <div className="logo">
                {/* <div className="hamburger-menu" onClick={toggleSidebar}>
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div> */}
                <img src={onepgrLogo} alt="Logo" />
            </div>

         
        </nav>
    );
};

export default NavBar;
